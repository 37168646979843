@use "sass:map";
@import "~bootstrap/scss/bootstrap";

$extra-colors: (
  notice: $orange,
  note: #9a9a9a,
  general: #a40b1a,
  trip: $secondary,
  trip_leg: $primary,
  hall_rental: #1aa179,
  gift: #57a6a8,
  entertainment: #7851a9,
  lodging: $danger,
  meal: $orange,
  car_rental: $warning,
  mileage: $success,
  general_trip: $info,
  unsubmitted: $danger,
  waiting_on_supervisor: $orange,
  submitted: $warning,
  needs_review: $success,
  paid: $info,
  requires_approval: $danger,
  initial_coding: $orange,
  coding_review: #d29b00,
  correction_review: $success,
  admin_review: $info,
  business_office_edit: $primary
);

$all-colors: map.merge($colors, $theme-colors);
$all-colors: map.merge($all-colors, $extra-colors);

@each $color, $value in $all-colors {
  .bg-light-#{$color} {
    background-color: lighten($value, 43%);
  }

  .callout-#{$color} {
    border-bottom: 5px solid $value;
    color: $value;
  }
}

@each $color, $value in $extra-colors {
  .text-#{$color} { color: $value; }
  .bg-#{$color} { background-color: $value; color: $white; }
  .border-#{$color} { border-color: $value; }
  .badge-#{$color} { background-color: $value; color: $white; }

  .alert-#{$color} {
    color: darken($value, 15%);
    background-color: lighten($value, 43%);
    border-color: lighten($value, 38%);
  }

  .btn-#{$color} {
    border-color: $value;
    background-color: $value;
    color: $white;
  }

  .btn-#{$color}:hover {
    border-color: darken($value, 15%);
    background-color: darken($value, 10%);
    color: $white;
  }

  .btn-outline-#{$color}, .btn-outline-#{$color}:hover {
    border-color: $value;
    color: $value;
  }
}

.bg-gray-1 { background-color: gray("100"); }
.bg-gray-2 { background-color: gray("200"); }
.bg-gray-3 { background-color: gray("300"); }
.bg-gray-4 { background-color: gray("400"); }
.bg-gray-5 { background-color: gray("500"); }
.bg-gray-6 { background-color: gray("600"); }
.bg-gray-7 { background-color: gray("700"); }
.bg-gray-8 { background-color: gray("800"); }
.bg-gray-9 { background-color: gray("900"); }
.bg-black { background-color: #000000; }
